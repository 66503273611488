/* src/styles/wordcloud.css */
.word-cloud {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(240, 237, 234);
}
.word-cloud-container {
    width: 80%;
    height: 200;  /* Adjust the height as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(241, 238, 243);
    border-color: rgb(36, 11, 180);
}


