/* src/styles/styles.css */
body {
  font-family: Arial, sans-serif;
  background-color: #867474;
  margin: 0;
  padding: 0;
}

.container {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.task-manager {
  text-align: left;
}

.task-list {
  margin-top: 20px;
}

.task {
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
}

.task p {
  margin: 5px 0;
}

.word-cloud-container {
  margin-top: 20px;
}

.sentiment-message {
    text-align: center;
    margin-top: 20px;
}

.sentiment-chart {
    width: 80%;
    height: 400;
    margin: 0 auto;
    margin-top: 20px;
   display: flex;
}

